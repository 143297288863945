import Vue from 'vue';
import Component from 'vue-class-component';
import AppNavDrawer from '@/core/app-nav-drawer/app-nav-drawer.vue';
import AppNavbar from '@/core/app-navbar/app-navbar.vue';
import AppFooter from '@/core/app-footer/app-footer.vue';
import Snackbar from '@/shared/components/snackbar/snackbar.vue';
import PromptDialog from '@/shared/components/prompt-dialog/prompt-dialog.vue';
import { Inject, Watch } from 'vue-property-decorator';
import TranslationService from '@/locale/translation.service';
import ApplicationNotificationService from '@/shared/socket/application-notification.service';
import { Subscription } from 'rxjs';
import AccountService from '@/account/account.service';
import { Authority } from '@/shared/security/authority';

@Component({
  components: { AppNavDrawer, AppNavbar, AppFooter, Snackbar, PromptDialog },
})
export default class App extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('applicationNotificationService') private applicationNotificationService: () => ApplicationNotificationService;
  @Inject('accountService') private accountService: () => AccountService;

  private currentLanguage = this.$store.getters.currentLanguage;
  private showDrawer = false;
  private subscription?: Subscription;
  private showUpdateNotification = false;

  @Watch('$route')
  public onRouteChanged(val: boolean): void {
    this.closeDrawer();
  }

  private get showNavDrawer() {
    return this.$route.name !== 'Login' && this.$route.name !== 'ResetPasswordInit' && this.$route.name !== 'ResetPasswordFinish';
  }

  private toggleDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  private closeDrawer() {
    this.showDrawer = false;
  }

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  @Watch('$store.getters.account')
  public async onLogon(newValue, oldValue) {
    if ((await this.isAuthenticated()) && newValue !== null && oldValue === null) {
      await this.init();
    } else {
      await this.destroyedSocket();
    }
  }

  async mounted() {
    window.addEventListener('online', async () => {
      const isAuth = await this.isAuthenticated();
      if (isAuth) {
        await this.init();
      }
    });
    window.addEventListener('offline', async () => {
      const isAuth = await this.isAuthenticated();
      if (isAuth) {
        await this.destroyedSocket();
      }
    });
  }

  public async destroyedSocket() {
    await this.applicationNotificationService().disconnect();
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  public async init() {
    this.showUpdateNotification = false;
    this.applicationNotificationService().connect();
    this.subscription = this.applicationNotificationService().subscribe(activity => {
      this.showUpdateNotification = true;
    });
  }

  private async isAuthenticated() {
    return await this.accountService().hasAnyAuthorityAndCheckAuth(Authority.USER);
  }

  public refreshApplication() {
    location.reload();
  }
}
