import * as SockJS from 'sockjs-client';
import { map, Observable } from 'rxjs';
import { RxStomp } from '@stomp/rx-stomp';

export default class ApplicationNotificationService {
  private rxStomp: RxStomp;
  private debugEnabled = false;

  constructor() {
    this.stomp = new RxStomp();
  }

  get stomp() {
    return this.rxStomp;
  }

  set stomp(rxStomp) {
    this.rxStomp = rxStomp;
    this.rxStomp.configure({
      webSocketFactory: () => {
        return new SockJS(this.buildUrl());
      },
      debug: (msg: string): void => {
        if (this.debugEnabled) {
          console.log(new Date(), msg);
        }
      },
    });
    this.rxStomp.activate();
  }

  public connect(): void {
    this.updateCredentials();
    return this.rxStomp.activate();
  }

  public disconnect(): Promise<void> {
    return this.rxStomp.deactivate();
  }

  private getAuthToken() {
    return localStorage.getItem('jhi-authenticationToken') || sessionStorage.getItem('jhi-authenticationToken');
  }

  private buildUrl(): string {
    // building absolute path so that websocket doesn't fail when deploying with a context path
    const loc = window.location;
    const baseHref = document.querySelector('base').getAttribute('href');
    const url = '//' + loc.host + baseHref + 'websocket/application';
    const authToken = this.getAuthToken();
    if (authToken) {
      return `${url}?access_token=${authToken}`;
    }
    return url;
  }

  private updateCredentials(): void {
    this.rxStomp.configure({
      webSocketFactory: () => {
        return new SockJS(this.buildUrl());
      },
    });
  }

  public subscribe(observer) {
    return this.rxStomp
      .watch('/topic/application')
      .pipe(map(imessage => imessage.body))
      .subscribe(observer);
  }
}
