import axios from 'axios';
import { IOrder } from '@/shared/model/order.model';
import { IPTVExportQuery } from '@/export/ptv-export-query.model';
import { IDirectionData } from '@/shared/model/direction-data.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IRoute } from '@/shared/model/route.model';

const baseApiUrl = 'api';

export default class PtvExportService {
  public findOrders(ptvExport: IPTVExportQuery, searchValue?: string, req?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/query-ptv-data?${buildPaginationQueryOpts(req)}${searchValue ? '&searchValue=' + searchValue : ''}`, ptvExport)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findAllOrdersForMap(ptvExport: IPTVExportQuery, searchValue?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/query-ptv-map-data${searchValue ? '?searchValue=' + searchValue : ''}`, ptvExport)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public exportPtvData(orders: IOrder[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/export-ptv-data`, orders, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendDeliveryMails(orders: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-delivery-mails`, orders)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendCustomerAcquisitionEmail(orders: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-customer-acquisition-mails`, orders)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendCustomerTaxNotificationEmail(orders: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-customer-tax-notification-email`, orders)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendOrderCancellationMails(orders: number[], reason: number) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${baseApiUrl}/send-order-cancellation-mails`,
          { orderIds: orders, cancellationReasonId: reason },
          {
            headers: { 'Content-type': 'application/json' },
          }
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendOrderRegistrationMails(orders: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-order-registration-mails`, orders)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public showDirections(addresses, optimize) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/show-directions?optimizedRoute=${optimize}`, addresses)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findNearestOrders(addresses: IDirectionData): Promise<IOrder[]> {
    return new Promise<IOrder[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/find-nearest`, addresses)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public calculateAveragePrice() {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/calculate-average-price`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  generateRoutePdf(directionDataParam: IDirectionData, routeParam: IRoute, mapContent: any) {
    routeParam.routeAddresses = routeParam.routeAddresses.map(ra => {
      delete ra.marker;
      delete ra.routePath;
      delete ra.points;

      return ra;
    });

    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${baseApiUrl}/route-pdf`,
          {
            directionData: directionDataParam,
            route: routeParam,
            mapContent: mapContent,
          },
          {
            headers: { 'Content-type': 'application/json' },
          }
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
