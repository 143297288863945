import axios from 'axios';

import { IOrder } from '@/shared/model/order.model';
import { IDriver } from '@/shared/model/driver.model';

const baseApiUrl = 'api';

export default class OrderCalendarService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/orders/calendar`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public openRoutePdf(deliveryDate: Date, driverId: number) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${baseApiUrl}/open-route-pdf`,
          {
            deliveryDate: deliveryDate,
            driverId: driverId,
          },
          {
            headers: { 'Content-type': 'application/json' },
          }
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
