var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("app-nav-drawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNavDrawer,
            expression: "showNavDrawer",
          },
        ],
        attrs: { toggleDrawer: _vm.showDrawer },
      }),
      _vm._v(" "),
      _c("app-navbar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNavDrawer,
            expression: "showNavDrawer",
          },
        ],
        on: { toggleDrawer: _vm.toggleDrawer, closeDrawer: _vm.closeDrawer },
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { "multi-line": "", timeout: "-1", top: "", right: "" },
          model: {
            value: _vm.showUpdateNotification,
            callback: function ($$v) {
              _vm.showUpdateNotification = $$v
            },
            expression: "showUpdateNotification",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-h6", attrs: { cols: "12" } }, [
                _vm._v(
                  " Es gibt ein neues Update. Bitte aktualisieren Sie die Seite. "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-0" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "pink", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.showUpdateNotification = false
                    },
                  },
                },
                [_vm._v(" Ignorieren ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success" },
                  on: { click: _vm.refreshApplication },
                },
                [_vm._v(" Aktualisieren ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("app-footer"),
      _vm._v(" "),
      _c("prompt-dialog"),
      _vm._v(" "),
      _c("snackbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }