import axios from 'axios';

const baseApiUrl = 'api/import-hbl-data';

export default class ExcelImportService {
  public uploadAndImport(importFile: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', importFile);
      formData.append('remove', 'true');

      axios
        .post(baseApiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
