// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import router from './router';
import * as config from './shared/config/config';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import VCurrencyField from 'v-currency-field';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
/* tslint:disable */

import ExtraCostsService from '@/extra-costs/extra-costs.service';
import ProviderService from '@/provider/provider.service';
import DriverService from '@/driver/driver.service';
import StatusService from '@/shared/services/status.service';
import ProductService from '@/product/product.service';
import DiscountService from '@/shared/services/discount.service';
import PaymentService from '@/shared/services/payment.service';
import TruckService from '@/truck/truck.service';
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here
import ExcelImportService from '@/import/excel-import.service';
import OrderCalendarService from '@/order-calendar/order-calendar.service';
import PtvExportService from '@/export/ptv-export.service';
import OrderService from '@/order/order.service';
import CustomerService from '@/customer/customer.service';
import AddressService from '@/shared/services/address.service';
import BillingsJournalService from '@/billings-journal/billings-journal.service';
import DeletedOrdersService from '@/deleted-orders/deleted-orders.service';
import ActivitiesService from '@/activities/activities.service';
import { UpdatePriceService, GetPricesService } from '@/update-price/update-price.service';
import AbsenceCalendarService from '@/absence-calendar/absence-calendar.service';
import VendorService from '@/vendor/vendor.service';
import DepotService from '@/depot/depot.service';
import CancellationReasonService from '@/cancellation-reason/cancellation-reason.service';
import DeliveryBillingService from '@/delivery-billing/delivery-billing.service';
import StockService from '@/stock/stock.service';
import ApplicationNotificationService from '@/shared/socket/application-notification.service';


import GmapVue from 'gmap-vue';

import BillingOverviewService from '@/billing-overview/billing-overview.service';
import '../content/material-icon/css/materialdesignicons.min.css'


/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
Vue.use(Vue2Filters);
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAxmhuaaAuJCgp-K90zAL24V3SWDrQz7_0',
    libraries: 'places',
    region: 'DE',
    language: 'de',
  },
  installComponents: true,
});
Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);
const vuetify = config.initVuetify(Vue);

const translationService = new TranslationService(store, i18n);
const accountService = new AccountService(store, translationService, router);

router.beforeEach(async (to, from, next) => {
  if (to.fullPath === '/') {
    next('/ptv-export');
  } else if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/login');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    userService: () => new UserManagementService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    customerService: () => new CustomerService(),
    orderService: () => new OrderService(),
    addressService: () => new AddressService(),
    extraCostsService: () => new ExtraCostsService(),
    providerService: () => new ProviderService(),
    driverService: () => new DriverService(),
    statusService: () => new StatusService(),
    productService: () => new ProductService(),
    discountService: () => new DiscountService(),
    paymentService: () => new PaymentService(),
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    cancellationReasonService: () => new CancellationReasonService(),
    excelImportService: () => new ExcelImportService(),
    orderCalendarService: () => new OrderCalendarService(),
    ptvExportService: () => new PtvExportService(),
    billingsJournalService: () => new BillingsJournalService(),
    deletedOrdersService: () => new DeletedOrdersService(),
    activitiesService: () => new ActivitiesService(),
    updatePriceService: () => new UpdatePriceService(),
    getPricesService: () => new GetPricesService(),
    absenceCalendarService: () => new AbsenceCalendarService(),
    truckService: () => new TruckService(),
    vendorService: () => new VendorService(),
    depotService: () => new DepotService(),
    deliveryBillingService: () => new DeliveryBillingService(),
    stockService: () => new StockService(),
    billingOverviewService: () => new BillingOverviewService(),
    applicationNotificationService: () => new ApplicationNotificationService(),
  },
  i18n,
  store,
  vuetify,
});
