var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { app: "", flat: "", "clipped-left": "" } },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleDrawer.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-toolbar-title",
        { staticClass: "title pl-0", on: { click: _vm.toHomePage } },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-img", {
                attrs: { src: "content/images/logo.svg", width: "35" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm.$t("global.title"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-none d-md-flex" },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.changeTheme } },
            [
              _c("v-icon", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.theme.dark
                        ? "mdi-white-balance-sunny"
                        : "mdi-weather-night"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAdminAuthority
        ? _c(
            "div",
            { staticClass: "d-flex d-sm-none" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    "x-large": "",
                    color: _vm.redButtonActive ? "red" : "green",
                  },
                  on: { click: _vm.redButton },
                },
                [_c("v-icon", [_vm._v(" mdi-checkbox-blank-circle ")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }