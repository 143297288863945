import axios from 'axios';
import { IContent24PriceEntry } from '@/shared/model/content24-price-entry.model';

const baseApiUrl = 'api/update-price';

export class UpdatePriceService {
  public updatePrice(priceEntries: IContent24PriceEntry[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, priceEntries, {
          headers: { 'Content-type': 'application/json' },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export class GetPricesService {
  public getPrices(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
