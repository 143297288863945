import AccountService from '@/account/account.service';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AppNavDrawer extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  $refs;
  @Prop() toggleDrawer: boolean;
  private hasAdminAuthorityValue = false;
  private hasManagerAuthorityValue = false;
  private navDrawer = false;
  private adminGroup = false;
  private dataGroup = false;
  private managerGroup = false;
  public drawer = false;

  private get adminGroupCardColor() {
    return this.adminGroup ? '' : 'transparent';
  }
  private get dataGroupCardColor() {
    return this.dataGroup ? '' : 'transparent';
  }
  private get managerGroupCardColor() {
    return this.managerGroup ? '' : 'transparent';
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
  private get redButtonActive(): boolean {
    return this.$store.getters['appStore/getRedButtonActive'];
  }

  @Watch('toggleDrawer')
  public ontoggleDrawerChanged(val: boolean): void {
    this.drawer = !this.drawer;
  }

  public get hasAdminAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_ADMIN')
      .then(value => {
        this.hasAdminAuthorityValue = value;
      });
    return this.hasAdminAuthorityValue;
  }

  public get hasManagerAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_MANAGER')
      .then(value => {
        this.hasManagerAuthorityValue = value;
      });
    return this.hasManagerAuthorityValue;
  }

  private drawerChanged() {
    this.navDrawer = !this.$refs.drawer.isMiniVariant;
  }

  created() {
    this.hasAdminAuthorityValue = false;
    this.hasManagerAuthorityValue = false;
  }

  public logout(): void {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.hasAdminAuthorityValue = false;
    this.hasManagerAuthorityValue = false;
    this.$store.commit('logout');
    this.$router.push('/login');
  }
}
